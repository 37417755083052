<script lang="ts">
    import { slide } from 'svelte/transition';
	import { enableAds } from './stores.js';

    import MaxodusAdBanner from './MaxodusAdBanner.svelte'
    import GoldbachAdBanner from './GoldbachAdBanner.svelte'

    export let enableFallback: boolean = true;
    export let setSize: boolean = true;
    export let goldbachBannerId: number;

</script>

{#if $enableAds >= 0 && $enableAds <= 2}
<div class="{setSize ? 'h-[255px] ' : ''}px-0 mx-auto mt-2 mb-2 overflow-hidden text-center bg-A1_white" out:slide> <!-- Goldbachs div is 255 in height although iframe is only 250 -->
    {#if $enableAds === 1}
        <MaxodusAdBanner />
    {:else if $enableAds === 2}
        <GoldbachAdBanner bannerId={goldbachBannerId} {enableFallback}/>
    {/if}
</div>
{/if}
