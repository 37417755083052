import Error404 from "./Error404.svelte";
import Index from "./Index.svelte";
import Test from "./routed/Test.svelte";
import News from "./routed/News.svelte";
import Apps from "./routed/Apps.svelte";
import Games from "./routed/Games.svelte";
import MeineServices from "./routed/MeineServices.svelte";
import UeberA1World from "./routed/UeberA1World.svelte";
import Wetter from "./routed/Wetter.svelte";
import XploreMusic from "./routed/XploreMusic.svelte";


// taken from https://codesandbox.io/embed/polished-dew-pckm7?fontsize=14&hidenavigation=1&module=%2FApp.svelte&theme=dark
// (or https://jackwhiting.co.uk/posts/refactoring-our-router-within-svelte/)

export default [
  {
    path: "/",
    component: Index
  },
  {
    path: "/test",
    component: Test
  },
  {
    path: "/sport-und-news",
    component: News
  },
  {
    path: "/games",
    component: Games
  },
  {
    path: "/service",
    component: MeineServices
  },
  {
    path: "/apps",
    component: Apps
  },
  {
    path: "/ueber-a1-world",
    component: UeberA1World
  },
  {
    path: "/wetter",
    component: Wetter
  },
  {
    path: "/xplore-music",
    component: XploreMusic
  },
  {
    path: "*",
    component: Error404
  }
];
