<script lang="ts">
    import SwiperCore, { Controller, Scrollbar, Navigation, A11y } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/svelte';
    import { EffectFade } from 'swiper';

    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/scrollbar';
    import 'swiper/css/effect-fade';


    let unclamp: Boolean = false;

    let imageSwiper: SwiperCore;
    let textSwiper: SwiperCore;

    const setImageSwiper = (e: CustomEvent) => {
      const [swiper] = e.detail;
      setTimeout(() => {
        imageSwiper = swiper;
      });
    };

    const setTextSwiper = (e: CustomEvent) => {
      const [swiper] = e.detail;
      setTimeout(() => {
        textSwiper = swiper;
      });
    };
  
</script>

<div class="px-6 mx-auto md:mx-0 "> <!-- todo: anderer Swiper bei MD breakpoint+? Ähnlich zu World 2019 mit 2 Elementen  -->
    <Swiper
        modules={[Controller, Scrollbar, Navigation, A11y, EffectFade]}
        navigation={{nextEl: '.nav-next', prevEl: '.nav-prev'}}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        on:swiper={setImageSwiper}
        effect={'fade'}
        controller={{ control: textSwiper }}>

        {#each [1,2,3,4,5] as i}
        <SwiperSlide>
            <figure class="mb-4">
                <img class="w-full aspect-[40/19] " src="/static/images/bigteaser.jpg" alt="Big Teaser"> 
            </figure>
        </SwiperSlide>
        <SwiperSlide>
            <figure class="mb-4">
                <img class="w-full aspect-[40/19] " src="/static/images/bigteaser2.jpg" alt="Big Teaser"> 
            </figure>
        </SwiperSlide>
        {/each}

        <div slot="container-end">
            <div class="absolute z-10 flex items-center justify-center p-2 -mt-8 nav-prev left-0.5 top-1/2 w-9 h-14 ">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.8418 8.825L3.02513 5L6.8418 1.175L5.6668 0L0.666797 5L5.6668 10L6.8418 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
            
            <div class="absolute z-10 flex items-center justify-center p-2 -mt-8 nav-next right-0.5 top-1/2 w-9 h-14 ">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.158203 8.825L3.97487 5L0.158203 1.175L1.3332 0L6.3332 5L1.3332 10L0.158203 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
        </div>
    </Swiper>

    <Swiper
        modules={[Controller, A11y]}
        slidesPerView={1}
        on:swiper={setTextSwiper}
        controller={{ control: imageSwiper }}>

        {#each [1,2,3,4,5,6,7,8,9,0] as i}
        <SwiperSlide>
            <div class="text-left text">
                <h1 class="mt-2 font-sans text-base font-normal ">Katze - Lorem ipsum</h1>
                <div class="text-xs text-A1_dgray">Do, 12 Jun um 08:51</div>
                <div class="my-2 text-sm">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div class="flex items-center justify-center">
                    <span class="sr-only">Zum Artikel</span>
                    <button class="relative h-10 py-2 pl-5 pr-[42px] overflow-hidden align-top whitespace-nowrap text-ellipsis m-2 border border-solid rounded max-w-sm min-w-[10rem] flex-nowrap text-A1_red border-A1_red ">
                                    Zum vollständigen Artikel
                                    <span class="after:leading-4 after:absolute after:top-1/2 after:right-5 after:border-l-A1_red after:border-b-A1_red after:border-b-2 after:border-l-2 after:inline-block after:-translate-y-1/2 after:-rotate-[135deg] after:-mb-[1px] after:ml-1.5 after:border-solid after:w-2 after:h-2"></span>
                        <!-- <svg class="inline-block h-2 w-2 absolute -mb-[1px] ml-[6px] right-5 top-4" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 8.825L3.81667 5L0 1.175L1.175 0L6.175 5L1.175 10L0 8.825Z" fill="currentColor"/>
                        </svg> -->
                    </button>
                </div>
                <!-- <button class="m-2">Zum Artikel</button> -->
            </div>
        </SwiperSlide>
        {/each}
    </Swiper>
</div>

<style>
/* figure {
    margin: 0;
    margin-bottom: 15px;
} */
/* h1 {
    @apply font-serif;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0;
} */

/* img {
    width: 100%;
} */
/* button {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 1rem 2rem;
    outline: none;
    border: 0;
    border-radius: 5px;
} */
/* .nav-prev, .nav-next {
    position: absolute;
    top: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 2
}
.nav-prev {
    left: 2px;
}
.nav-next {
    right: 2px;
} */
</style>