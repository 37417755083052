<script lang="ts">
    import type { CatType } from "./types/cat.d";

	import Carousel from "./Carousel.svelte"
	import AdBanner from "./AdBanner.svelte"
	import AdUnderstitial from "./AdUnderstitial.svelte"

	import Twitter from "./template/twitter.svelte";
	import Service from "./template/service.svelte";
	import CarouselSmall from "./Carousel_small.svelte";
	import CarouselNews from "./Carousel_news.svelte";
	import BigTeaser from "./BigTeaser.svelte";
	import Quicklaunch from "./template/quicklaunch.svelte";

	let cats1: CatType[] = [];
	let cats2: CatType[] = [];
	let cats3: CatType[] = [];
	let cats4: CatType[] = [];
	let cats5: CatType[] = [];
	let cats6: CatType[] = [];

  	let fakerand = Math.floor(Math.random() * 10 + 1);

	
	interface BlockOrder {
        id: string;
		fixed?: boolean;
    }

  	let blockOrder: BlockOrder[];
	if (fakerand > 9) {
		blockOrder = [
			{ id: 'bigteasers-and-mya1', fixed: true },
			{ id: 'quicklaunch', fixed: true },			
			{ id: 'carousel-cats1' },
			{ id: 'carousel-cats2' },
			{ id: 'ad-understitial', fixed: true },
			{ id: 'carousel-cats3' },
			{ id: 'carousel-cats4' },
			{ id: 'twitter' },
			{ id: 'news' },
			{ id: 'ad-banner', fixed: true },			
			{ id: 'carousel-cats5' },
			{ id: 'carousel-cats6' },
			{ id: 'ad-banner2', fixed: true },
			{ id: 'service' },
		];
	} else if (fakerand < 2) {
		blockOrder = [
			{ id: 'bigteasers-and-mya1', fixed: true },
			{ id: 'quicklaunch', fixed: true },			
			{ id: 'carousel-cats2' },
			{ id: 'carousel-cats1' },
			{ id: 'ad-understitial', fixed: true },
			{ id: 'carousel-cats3' },
			{ id: 'carousel-cats4' },
			{ id: 'twitter' },
			{ id: 'news' },
			{ id: 'ad-banner', fixed: true },			
			{ id: 'carousel-cats5' },
			{ id: 'carousel-cats6' },
			{ id: 'ad-banner2', fixed: true },
			{ id: 'service' },
		];
	} else if (fakerand < 9) {
		blockOrder = [
			{ id: 'bigteasers-and-mya1', fixed: true },
			{ id: 'quicklaunch', fixed: true },			
			{ id: 'ad-understitial', fixed: true },
			{ id: 'carousel-cats1' },
			{ id: 'carousel-cats2' },
			{ id: 'ad-banner', fixed: true },			
			{ id: 'carousel-cats3' },
			{ id: 'carousel-cats4' },
			{ id: 'twitter' },
			{ id: 'news' },
			{ id: 'carousel-cats5' },
			{ id: 'carousel-cats6' },
			{ id: 'ad-banner2', fixed: true },
			{ id: 'service' },
		];
			
	} else {
		blockOrder = [
			{ id: 'bigteasers-and-mya1', fixed: true },
			{ id: 'quicklaunch', fixed: true },			
			{ id: 'ad-banner', fixed: true },			
			{ id: 'carousel-cats1' },
			{ id: 'carousel-cats2' },
			{ id: 'carousel-cats3' },
			{ id: 'carousel-cats4' },
			{ id: 'twitter' },
			{ id: 'news' },
			{ id: 'ad-understitial', fixed: true },
			{ id: 'carousel-cats5' },
			{ id: 'carousel-cats6' },
			{ id: 'ad-banner2', fixed: true },
			{ id: 'service' },
		];
		
	}

</script>

	{#each blockOrder as item (item.id)}
			{#if item.id === 'ad-banner'}
				{#if fakerand > 9} <!-- faking moving blocs DEFAULT -->
					<AdBanner setSize={false} enableFallback={false} goldbachBannerId={3}/>
				{:else if fakerand < 2} <!-- faking moving blocs Content Promo -->
					<AdBanner setSize={false} enableFallback={false} goldbachBannerId={3}/>
				{:else if fakerand < 9} <!-- faking moving blocs US Promo -->
					<AdBanner setSize={false} enableFallback={false} goldbachBannerId={3}/>
				{:else} <!-- faking moving blocs CA Promo -->
					<AdBanner goldbachBannerId={3}/>
				{/if}

			{:else if item.id === 'ad-banner2'}
				<AdBanner setSize={false} enableFallback={false} goldbachBannerId={2}/>

			{:else if item.id === 'ad-understitial'}
				{#if fakerand > 9} <!-- faking moving blocs DEFAULT -->
					<AdUnderstitial setSize={false} enableFallback={false}/>
				{:else if fakerand < 2} <!-- faking moving blocs Content Promo -->
					<AdUnderstitial setSize={false} enableFallback={false}/>
				{:else if fakerand < 9} <!-- faking moving blocs US Promo -->
					<AdUnderstitial/>
				{:else} <!-- faking moving blocs CA Promo -->
					<AdUnderstitial setSize={false} enableFallback={false}/>
				{/if}

			{:else if item.id === 'bigteasers-and-mya1'}
				<BigTeaser/>

			{:else if item.id === 'carousel-cats1'}
				<Carousel cats={cats1} id="cats-1" header="Content 1" />

			{:else if item.id === 'carousel-cats2'}
				<Carousel cats={cats2} id="cats-2" header="Content 2" />

			{:else if item.id === 'carousel-cats3'}
				<Carousel cats={cats3} id="cats-3" header="Content 3" />
			
			{:else if item.id === 'carousel-cats4'}
				<Carousel cats={cats4} id="cats-4" header="Content 4" />
			
			{:else if item.id === 'carousel-cats5'}
				<CarouselSmall cats={cats5} id="cats-5" header="Content 5" />
			
			{:else if item.id === 'carousel-cats6'}
				<CarouselSmall cats={cats6} id="cats-6" header="Content 6" />

			{:else if item.id === 'news'}
				<CarouselNews/>

			{:else if item.id === 'quicklaunch'}
				<Quicklaunch />

			{:else if item.id === 'service'}
				<Service/>

			{:else if item.id === 'twitter'}
				<Twitter/>

			{/if}
	{/each}
