<script>
  import { fade, slide } from 'svelte/transition';
  import { linear, quadInOut } from 'svelte/easing';
</script>

<div class="min-h-full ">
  <!-- Off-canvas menu for desktop, show/hide based on off-canvas menu state. -->
    
    <div  transition:fade={{ duration: 100, easing: linear }} class="fixed top-[5.625rem] z-30 inset-x-0	h-full bg-A1_lllgray bg-opacity-75 hidden md:block"></div> 

    <!-- <div class="fixed inset-0 z-40 flex"> -->

    <!-- Mobile menu, show/hide based on menu state. transition:slide={{ duration: 100, easing: quadInOut }} -->

    <div transition:slide={{ duration: 100, easing: linear }} class="fixed top-[5.625rem]  z-40 right-0 w-[85vw] max-w-[19.375rem] hidden md:block " id="desktop-menu">
    <div class="space-y-1">
      <!-- Current Level1: "bg-A1_white border-A1_lllgray text-A1_red", Default: "bg-A1_white border-A1_lllgray text-A1_black hover:text-A1_red" -->
      <a href="/" class="block py-2 pl-3 pr-4 text-base  border-transparent bg-A1_white border-A1_lllgray text-A1_red">Item1</a>
      <a href="/" class="block py-2 pl-3 pr-4 text-base  border-transparent bg-A1_white border-A1_lllgray text-A1_black hover:text-A1_red">Item2</a>
      <a href="/" class="block py-2 pl-3 pr-4 text-base  border-transparent bg-A1_white border-A1_lllgray text-A1_black hover:text-A1_red">Item3</a>
      <a href="/" class="block py-2 pl-3 pr-4 text-base  border-transparent bg-A1_white border-A1_lllgray text-A1_black hover:text-A1_red">Item4</a>
    </div>

  </div>

</div>
