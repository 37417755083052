<script lang="ts">
	import { Workbox } from 'workbox-window';
    import type { WorkboxLifecycleWaitingEvent } from "workbox-window";

    import type { CatType } from "./types/cat.d";

	export let cats1: CatType[];
	export let cats2: CatType[];
	export let cats3: CatType[];
	
	if ('serviceWorker' in navigator) {
		const wb = new Workbox('/sw.js');

		// refresh old tabs when new sw is installed (approach #2 from https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68)
		// see also: https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
		let refreshing: boolean = false;
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (refreshing) return;
			refreshing = true;
			window.location.reload();
		});

		wb.register();

		navigator.serviceWorker.addEventListener('message', async event => {
			if (event.data.meta === 'workbox-broadcast-update') {
				const {cacheName, updatedURL} = event.data.payload;

				const cache = await caches.open(cacheName);
    			const updatedResponse = await cache.match(updatedURL);
    			const updatedContent = await updatedResponse?.json();

				if (updatedURL.includes("/cats-1.json")) {
					cats1 = updatedContent.content;
				} else if (updatedURL.includes("/cats-2.json")) {
					cats2 = updatedContent.content;
				} else if (updatedURL.includes("/cats-3.json")) {
					cats3 = updatedContent.content;
				}
			}
		});
	}
</script>
