<script lang="ts">
    import { slide } from 'svelte/transition';
	import { enableAds } from './stores.js';

    import MaxodusAdUnderstitial from './MaxodusAdUnderstitial.svelte'
    import GoldbachAdUnderstitial from './GoldbachAdUnderstitial.svelte'

    export let enableFallback: boolean = true;
    export let setSize: boolean = true;

</script>

{#if $enableAds >= 0 && $enableAds <= 2}
<div class="{setSize ? 'h-[602px] ' : ''}px-0 mx-auto mt-2 mb-2 text-center md:hidden "  out:slide>
    {#if $enableAds === 1}
        <MaxodusAdUnderstitial />
    {:else if $enableAds === 2}
        <GoldbachAdUnderstitial {enableFallback} />
    {/if}
</div>
{/if}
