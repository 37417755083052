<script lang="ts">
    import { onMount } from "svelte"
	import { goldbachScriptStatus } from './stores.js';

    // TODO: declaring goldbach specific members does not work yet
    import type { GoldbachWindow } from "./types/goldbach.window.d";

    import MaxodusAdUnderstitial from "./MaxodusAdUnderstitial.svelte"
    import GoldbachScripts from "./GoldbachScripts.svelte"

    export let enableFallback: boolean = true;

    let bannerDiv: HTMLElement;
    let fallback: boolean = false;
    onMount(() => {
        // check after a period if an ad was loaded -> otherwise fallback
        const timeout = enableFallback ? setTimeout(() => {
            if (bannerDiv.childElementCount === 1) {
                // using our banner
                fallback = true;
            }
        }, 7500) : undefined;

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    })

    $: if ($goldbachScriptStatus === 3 && !fallback) {
        (<any>window).setgbpartnertag4124 = true;

        let script = document.createElement("script");
        script.type = "text/javascript";
        script.appendChild(document.createTextNode('try { gbcallslot4124("' + bannerDiv.id + '", ""); } catch (e) {}'));

        bannerDiv.appendChild(script);
    }


</script>

<GoldbachScripts/>

{#if !fallback} 
    <div class="inline-block" bind:this={bannerDiv} id="div-ad-gds-4124-1"></div>
{:else}
    <MaxodusAdUnderstitial/>
{/if}
