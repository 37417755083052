import { writable } from 'svelte/store';

// status (TODO: use typescript)
// * 0 unclear
// * 1 our ads
// * 2 goldbach ads
export const enableAds = writable(0);

// goldbach loading status (TODO: use typescript)
// * 0 no scripts loaded
// * 1 loading scripts
// * 2 config loaded
// * 3 adtag loaded
export const goldbachScriptStatus = writable(0);