<div class="h-48 mx-auto my-3 sm:h-52 text-A1_llgray ">
  <h1 class="mb-4 font-serif text-2xl leading-8 text-center text-A1_black">Aktuell auf Twitter</h1>
  <div class="font-sans leading-8 text-center text-A1_black">
    <span class="mx-3 text-xl">twitter1</span>
    <span class="mx-3 text-sm">twitter2</span>
    <span class="mx-3 text-2xl">twitter3</span>
    <span class="mx-3 text-xs">twitter4</span>
    <span class="mx-3 text-sm">twitter5</span>
    <span class="mx-3 text-sm">twitter6</span>
    <span class="mx-3 text-xl">twitter7</span>
    <span class="mx-3 text-xs">twitter8</span>
    <span class="mx-3 text-xl">twitter9</span>
  </div>
</div>