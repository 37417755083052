<script>
	import router from "page";
	import routes from "./routes.js";

    import Tailwindcss from './Tailwindcss.svelte';

    import CookieConsent from "./CookieConsent.svelte"
    import ServiceWorkerHandler from "./ServiceWorkerHandler.svelte"

    import Header from "./template/header.svelte";
    import Footer from "./template/footer.svelte";
    import Cse from "./template/cse.svelte";

	let cats1 = [];
	let cats2 = [];
	let cats3 = [];

    let page;
	let params;
	let pageChangeSwitch = true;

    // Iterate through the routes
	routes.forEach(route => {
		router(
			route.path,

			(ctx, next) => {
				params = ctx.params;
				next();
			},

			() => {
				page = route.component;
				pageChangeSwitch = !pageChangeSwitch;
			}
		);
	});

	// Start the router
	router.start();
</script>

<ServiceWorkerHandler bind:cats1 bind:cats2 bind:cats3/>

<Tailwindcss />

<CookieConsent oneTrustId="034769c2-6dcb-4461-8d72-39c2646db8a8-test"/>

<Header/>
<div class="max-w-[1540px] md:grid mx-auto md:grid-cols-2 md:gap-4 md:mt-4"> <!-- Nur ein quick fix bis Carousel Layout steht -->
	<Cse/>

    {#if pageChangeSwitch}
    <svelte:component this={page} {...params} />
    {:else}
    <svelte:component this={page} {...params} />
    {/if}

</div>

<Footer/>
