<script>
import { fade, slide } from 'svelte/transition';
import { linear, quadInOut } from 'svelte/easing';
</script>

<div class="min-h-full">
   <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div transition:fade={{ duration: 100, easing: linear }} class="fixed inset-x-0 z-30 h-full bg-opacity-75 top-12 bg-A1_lllgray md:hidden"></div> 
  <div transition:slide={{ duration: 100, easing: linear }} class="bg-A1_white fixed overflow-hidden h-screen block z-40 top-12 right-0 w-[85vw] max-w-[19.375rem] md:hidden" id="mobile-menu">
    <div class="relative h-full max-h-full overflow-x-hidden overflow-y-scroll">
      <div class="px-8 pt-6 ">
      <nav class="mb-8">
        <ul>
          <li>
            <a href="/" class="block py-4 pr-8 text-base border-b-[0.0625rem] border-b-A1_llgray  border-transparent bg-A1_white  text-A1_red">Item1</a>
          </li>
          <li>
            <a href="/" class="block py-4 pr-8 border-b-[0.0625rem] border-b-A1_llgray text-base  border-transparent bg-A1_white  text-A1_black hover:text-A1_red">Item2</a>
          </li>
          <li>
            <a href="/" class="block py-4 pr-8 border-b-[0.0625rem] border-b-A1_llgray text-base  border-transparent bg-A1_white  text-A1_black hover:text-A1_red">Item3</a>
          </li>
          <li>
            <a href="/" class="block py-4 pr-8 border-b-[0.0625rem] border-b-A1_llgray text-base  border-transparent bg-A1_white  text-A1_black hover:text-A1_red">Item4</a>
          </li>
        </ul>
      </nav>
      </div>
      <div class="space-y-1"></div>
      <!-- Current Level1: "bg-A1_white border-A1_lllgray text-A1_red", Default: "bg-A1_white  text-A1_black hover:text-A1_red" -->
    </div>
  </div>
</div>