<script lang="ts">
    import { A11y, FreeMode, Pagination, Navigation } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/svelte';

    import type { CatType } from "./types/cat.d";

    import 'swiper/css';
    import 'swiper/css/navigation';

	import { onMount } from 'svelte'
	import { fade } from 'svelte/transition';

    export let id: String;
    export let header: String;

    export let cats: CatType[];

    onMount(async () => {
		const res = await fetch(`./json/${id}.json`);
		const json = await res.json();

        cats = json.content;
	});

    $: console.log(cats);
    
</script>

<div class="md:col-span-2  my-3  h-[17rem] mobileL:h-72 md:h-[30rem] overflow-hidden px-0 mx-0 py-2 ">
    <div class="absolute md:hidden mt-1.5 ">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>    
    </div>
    <h1 class="font-serif text-2xl leading-8 text-center text-A1_black">{header}</h1>
    <div class="mb-4 text-base text-center text-A1_red">Alle anzeigen
        <span class="after:-mb-0.5 after:inline-block after:leading-4 after:border-l-A1_red after:border-b-A1_red after:border-b-2 after:border-l-2 after:-translate-y-1/2 after:-rotate-[135deg] after:border-solid after:w-2 after:h-2"></span>

    </div>

    {#key cats}
    <Swiper
        modules={[A11y, Navigation, FreeMode]}
        centeredSlides
        navigation={{nextEl: '.nav-next', prevEl: '.nav-prev'}}
        freeMode
        loop
        loopAdditionalSlides={1}
        slidesPerView={1}
        breakpoints={{
            // when window width is >= 360px
            360: {
              slidesPerView: 2,
              spaceBetween: 15,
              
            },
            // when window width is >= 576px
            576: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesOffsetAfter: 200
            },
            // when window width is >= 768px - Desktop Layout1
            768: {
              slidesPerView: 4,
              spaceBetween: 25,
              slidesOffsetAfter: 120
            },
            // when window width is >= 1045px - Desktop Layout2
           /*  1045: {
              slidesPerView: 5,
              spaceBetween: 20,
              slidesOffsetAfter: 200
            } */
          }}
        initialSlide={1}>
    
        {#each cats as cat (cat.id)}
        <SwiperSlide>
            <div class="flex items-center justify-center">
                <div>
                    <figure class="">
                        <img class="rounded w-36 aspect-square mobileL:w-40 md:w-80" src="/static/images/{cat.image}" alt="Cat {cat.id}" transition:fade/>
                    </figure>
                    <div class="overflow-hidden font-serif text-base leading-5 text-ellipsis text-A1_black">Line1</div>
                    <div class="overflow-hidden font-sans leading-5 text-ellipsis text-A1_dgray">Line 2</div>
                </div>
            </div>
        </SwiperSlide>
        {/each}

        <div slot="container-end">
            <div class="absolute flex items-center justify-center p-2 rounded nav-prev bg-A1_red w-9 h-14 top-1/2 z-10 -mt-12 mobileL:-mt-18 left-0.5">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.8418 8.825L3.02513 5L6.8418 1.175L5.6668 0L0.666797 5L5.6668 10L6.8418 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
            
            <div class="absolute flex items-center justify-center p-2 rounded nav-next bg-A1_red w-9 h-14 top-1/2 z-10 -mt-12 mobileL:-mt-18 right-0.5">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.158203 8.825L3.97487 5L0.158203 1.175L1.3332 0L6.3332 5L1.3332 10L0.158203 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
        </div>
    </Swiper>
    {/key}
</div>

<!-- <style>
div.container {
    position: relative;
}
/* img {
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    height: auto;
/* }  
/* .nav-prev, .nav-next {
    position: absolute;
    top: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 2
}
.nav-prev {
    left: 2px;
}
.nav-next {
    right: 2px;
} 
*/
</style> -->