<script lang="ts">
    import SwiperCore, { Controller, Scrollbar, Navigation, A11y } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/svelte';
    import { EffectFade } from 'swiper';

    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/scrollbar';
    import 'swiper/css/effect-fade';


    let unclamp: Boolean = false;

    let imageSwiper: SwiperCore;
    let textSwiper: SwiperCore;

    const setImageSwiper = (e: CustomEvent) => {
      const [swiper] = e.detail;
      setTimeout(() => {
        imageSwiper = swiper;
      });
    };

    const setTextSwiper = (e: CustomEvent) => {
      const [swiper] = e.detail;
      setTimeout(() => {
        textSwiper = swiper;
      });
    };
  
</script>

<div class="px-0 mx-auto md:mx-0 ">
    <Swiper
        modules={[Controller, Scrollbar, Navigation, A11y, EffectFade]}
        navigation={{nextEl: '.nav-next', prevEl: '.nav-prev'}}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        on:swiper={setImageSwiper}
        effect={'fade'}
        controller={{ control: textSwiper }}>

        {#each [1,2,3,4,5] as i}
        <SwiperSlide>
            <figure class="mb-4">
               <img class="w-full h-[calc(100vw*0.475)] md:h-full" src="/static/images/bigteaser.jpg" alt="Big Teaser"> <!-- calc fix to stop this layout shift. Needs to be implemented properly -->
            </figure>
        </SwiperSlide>
        <SwiperSlide>
            <figure class="mb-4">
               <img class="w-full h-[calc(100vw*0.475)] md:h-full" src="/static/images/bigteaser2.jpg" alt="Big Teaser"> <!-- calc fix to stop this layout shift. Needs to be implemented properly -->
            </figure>
        </SwiperSlide>
        {/each}

        <div slot="container-end">
            <div class="flex items-center justify-center p-2 rounded nav-prev bg-A1_red w-9 h-14 ">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.8418 8.825L3.02513 5L6.8418 1.175L5.6668 0L0.666797 5L5.6668 10L6.8418 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
            
            <div class="flex items-center justify-center p-2 rounded nav-next bg-A1_red w-9 h-14 ">
                <div class="text-A1_white">
                  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.158203 8.825L3.97487 5L0.158203 1.175L1.3332 0L6.3332 5L1.3332 10L0.158203 8.825Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
        </div>
    </Swiper>

    <Swiper
        modules={[Controller, A11y]}
        slidesPerView={1}
        on:swiper={setTextSwiper}
        controller={{ control: imageSwiper }}>

        {#each [1,2,3,4,5,6,7,8,9,0] as i}
        <SwiperSlide>
            <div class="m-2 text-left text">
                <h1 class="my-2 font-serif text-xl ">Katze - Lorem ipsum</h1> <!-- height added for zero layout shift -->
                <div class="my-2" class:unclamp on:click={() => unclamp = !unclamp}>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisici elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div class="flex items-center justify-center">
                    <span class="sr-only">Zum Artikel</span>
                    <button class="relative h-10 py-2 pl-5 pr-[42px] overflow-hidden align-top whitespace-nowrap text-ellipsis m-2  rounded max-w-sm min-w-[10rem] flex-nowrap bg-A1_red text-A1_white  ">
                        Zum Artikel
                        <span class="after:absolute after:top-1/2 after:right-5 after:border-l-A1_white after:border-b-A1_white after:border-b-2 after:border-l-2 after:inline-block after:-translate-y-1/2 after:-rotate-[135deg] after:border-solid after:-mb-[1px] after:ml-1.5 after:w-2 after:h-2"></span>
                    </button>
                </div>
                <!-- <button class="m-2">Zum Artikel</button> -->
            </div>
        </SwiperSlide>
        {/each}
    </Swiper>
</div>

<style>
/* figure {
    margin: 0;
    margin-bottom: 15px;
} */
/* h1 {
    @apply font-serif;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0;
} */
div.text div {
    overflow: hidden;
    max-height: 3em;
}
div.text div.unclamp {
    max-height: 9em;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
}
div.text div p {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0;
}
div.text div.unclamp p {
    -webkit-line-clamp: 6;
    animation: open 0.3s linear 0s forwards;
}
@keyframes open {
  from {
    -webkit-line-clamp: 2;
    -line-clamp: 2;
  }
  to {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }
}

/* img {
    width: 100%;
} */
/* button {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 1rem 2rem;
    outline: none;
    border: 0;
    border-radius: 5px;
} */
.nav-prev, .nav-next {
    position: absolute;
    top: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 2
}
.nav-prev {
    left: 2px;
}
.nav-next {
    right: 2px;
}
</style>