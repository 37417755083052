<script>
  import Desktopmenu from "./desktopmenu.svelte";
  import Mobilemenu from "./mobilemenu.svelte";
  let MMenuShow = false;
  let MenuShow = false;
</script>


<div class="fixed z-50 w-screen border-b-A1_llgray  border-b-[0.0625rem] bg-A1_white ">
    <div class="  max-w-[1540px] w-full mx-auto items-center  flex flex-row justify-between px-2.5 xl:px-0 h-12 md:h-[5.625rem]"> <!-- Volle Breite, Elemente horizontal aufteilen md:h-[3.75rem] wenn gescrollt-->
        <div class="w-fit place-content-start shrink-0"> <!-- logo element, sticky left, no shrink -->
            <img class="block w-12 h-12 md:hidden" src="/static/images/A1Logo.png" alt="A1 World"> <!--logos/A1Logo_s.png -->
            <img class="hidden w-[5.625rem] h-[5.625rem] md:block" src="/static/images/A1Logo.png" alt="A1 World">
    <!--             <img class="hidden w-[3.75rem] h-[3.75rem] md:block" src="logos/A1Logo.png" alt="A1 World"> --> <!-- size when scrolled -->
        </div>
        <div class="content-center justify-center hidden h-6 font-sans text-lg leading-6 xl:ml-6 xl:flex xl:space-x-8"><!-- menu items, centered  -->
            <a href="/" class="items-center ml-[1.625rem] text-A1_red"> Active </a>
            <a href="/" class="items-center ml-[1.625rem] text-A1_black hover:text-A1_red"> Item1 </a>
            <a href="/" class="items-center ml-[1.625rem] text-A1_black hover:text-A1_red relative after:absolute pr-6 after:right-[0.1875rem] after:bg-center after:bg-no-repeat after:bg-contain after:absolute after:top-[calc(50%-0.3125rem)] after:bg-[url('/static/images/a1_arrow_external.svg')] after:inline-block after:w-4 after:h-2.5"> Business </a>
            <a href="/" class="items-center ml-[1.625rem] text-A1_black hover:text-A1_red"> Item3 </a>
        </div>
        <div class="hidden md:ml-6 md:flex md:items-center">
            <button type="button" class="pt-3.5 px-[0.9375rem] pb-2.5 mr-0.5 text-A1_black bg-A1_white  hover:text-A1_red  ">
                <span class="sr-only">Hilfe</span>
                <svg class="w-[1.125rem] h-[1.125rem]" width="19" height="18" viewBox="0 0 19 18" fill="none" stroke-width="0" stroke="currentColor"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg"> 
                    <path d="M16.077 0.961914H2.92308L0 3.88499V10.4619L2.92308 13.385H13.1538V17.0388H14.6154L19 13.385V3.88499L16.077 0.961914ZM17.5385 12.6542L14.6154 14.8465V11.9234H3.65387L1.46154 9.73114V4.61574L3.65387 2.42341H15.3462L17.5385 4.61574L17.5385 12.6542ZM8.76927 11.1927H10.2308V9.73114H8.76927V11.1927ZM10.9616 3.1542H8.03848L6.57694 4.61574V6.07728H8.03848V5.34649L8.76927 4.61574H10.2308L10.9616 5.34649V6.07728L10.2308 6.80807H8.76927V9.00035H10.2308V8.26957H10.9616L12.4231 6.80803V4.61574L10.9616 3.1542Z" fill="currentColor"></path> 
                </svg>
            </button>
            <button type="button" class="pt-3.5 px-[0.9375rem] pb-2.5 mr-0.5 text-A1_black bg-A1_white  hover:text-A1_red ">
                <span class="sr-only">Profil</span>
                <svg class="w-[1.125rem] h-[1.125rem]" width="16" height="18" viewBox="0 0 16 18" fill="none" stroke-width="0" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.35249 0H5.7375C4.92551 1.35833 4.51222 2.91779 4.54499 4.5C4.51673 6.08366 4.93522 7.64321 5.75249 9H9.36749C10.1742 7.6401 10.5823 6.08076 10.545 4.5C10.5778 2.91779 10.1645 1.35833 9.35249 0ZM12.795 10.5H2.24998L0 18H15.045L12.795 10.5Z" fill="currentColor"/>
                </svg>
            </button>
            <button type="button" on:click={() => (MenuShow = !MenuShow)} class="pt-3.5 px-[0.9375rem] pb-2.5 mr-0.5 text-A1_black bg-A1_white  hover:text-A1_red ">
                <span class="sr-only">Menü öffnen</span>
                <svg class=" block w-[1.125rem] h-[1.125rem] text-A1_black" xmlns="http://www.w3.org/2000/svg" fill="none"  aria-hidden="true">
                    <path d="M1.937 2.34h14.126v3.008H1.937V2.34zm0 5.157h14.126v3.006H1.937V7.497zm0 5.155h14.126v3.007H1.937v-3.007z" fill="currentColor"></path>
                </svg>
            </button>
        </div>
        <div class="flex justify-end md:hidden ">
            <button type="button" class="pt-3.5 px-[0.9375rem] pb-2.5 mr-0.5 text-A1_black bg-A1_white  hover:text-A1_red  ">
                <span class="sr-only">Hilfe</span>
                <svg class="w-[1.125rem] h-[1.125rem]" width="19" height="18" viewBox="0 0 19 18" fill="none" stroke-width="0" stroke="currentColor"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg"> 
                    <path d="M16.077 0.961914H2.92308L0 3.88499V10.4619L2.92308 13.385H13.1538V17.0388H14.6154L19 13.385V3.88499L16.077 0.961914ZM17.5385 12.6542L14.6154 14.8465V11.9234H3.65387L1.46154 9.73114V4.61574L3.65387 2.42341H15.3462L17.5385 4.61574L17.5385 12.6542ZM8.76927 11.1927H10.2308V9.73114H8.76927V11.1927ZM10.9616 3.1542H8.03848L6.57694 4.61574V6.07728H8.03848V5.34649L8.76927 4.61574H10.2308L10.9616 5.34649V6.07728L10.2308 6.80807H8.76927V9.00035H10.2308V8.26957H10.9616L12.4231 6.80803V4.61574L10.9616 3.1542Z" fill="currentColor"></path> 
                </svg>
            </button>
            <button type="button" class="pt-3.5 px-[0.9375rem] pb-2.5 mr-0.5 text-A1_black bg-A1_white  hover:text-A1_red ">
                <span class="sr-only">Profil</span>
                <svg class="w-[1.125rem] h-[1.125rem]" width="16" height="18" viewBox="0 0 16 18" fill="none" stroke-width="0" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.35249 0H5.7375C4.92551 1.35833 4.51222 2.91779 4.54499 4.5C4.51673 6.08366 4.93522 7.64321 5.75249 9H9.36749C10.1742 7.6401 10.5823 6.08076 10.545 4.5C10.5778 2.91779 10.1645 1.35833 9.35249 0ZM12.795 10.5H2.24998L0 18H15.045L12.795 10.5Z" fill="currentColor"/>
                </svg>
            </button>
            <!-- Mobile menu button -->
            <button type="button" on:click={() => (MMenuShow = !MMenuShow)}  class="inline-flex items-center justify-center pt-3.5 pb-2.5 px-[0.9375rem] mr-0.5 text-A1_black" aria-controls="mobile-menu" aria-expanded="false">
                <span class="sr-only">Menü öffnen</span>
                <svg class=" block w-[1.125rem] h-[1.125rem] text-A1_black" xmlns="http://www.w3.org/2000/svg" fill="none"  aria-hidden="true">
                    <path d="M1.937 2.34h14.126v3.008H1.937V2.34zm0 5.157h14.126v3.006H1.937V7.497zm0 5.155h14.126v3.007H1.937v-3.007z" fill="currentColor"></path>
                </svg>
            </button>
        </div>
    </div>
</div>
<div class="h-12 md:h-[5.625rem]"></div> <!-- dummy -->
{#if MenuShow}
<Desktopmenu />
{/if}

{#if MMenuShow}
<Mobilemenu />
{/if}

