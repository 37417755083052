<script lang="ts">
    import { onMount } from "svelte"
	import { goldbachScriptStatus } from './stores.js';

    // TODO: declaring goldbach specific members does not work yet
    import type { GoldbachWindow } from "./types/goldbach.window.d";

    const loadScript = (id: string, url: string, callback: Function) => {
        let script = document.createElement("script")
        script.type = "text/javascript";
        script.id = id;
        script.async = true;
        script.onload = function() {
            callback();
        };
        script.src = url;
        document.getElementsByTagName("head")[0]?.appendChild(script);
    }

    onMount(() => {
        if (!document.getElementById("gbadtag") && $goldbachScriptStatus === 0) {
            $goldbachScriptStatus = 1;
            loadScript("gbconfigscript", "//gbucket.at/config/config_mobile.js", () => {
                $goldbachScriptStatus === 2;

                (<any>window).gbucket = (<any>window).gbucket || {}; 
                if (typeof((<any>window).setgbtargetingobj) == 'undefined') {
                    (<any>window).setgbtargetingobj = {};
                }

                loadScript("gbadtag", "//gbucket.at/GBAA_Test_Scripte_Ads/Standardformate/GBNext_US.js", () => {
                    $goldbachScriptStatus = 3;
                })
            });
        }
    })
</script>
