<script>
	import { onMount } from 'svelte'
	import { enableAds } from './stores.js';

	export let oneTrustId;

	function handleCookieConsent() {
		if (OneTrust) {
			OneTrust.OnConsentChanged(function() {
				console.log("consent changed " + OnetrustActiveGroups)
			})
		}

		if (OneTrust && OnetrustActiveGroups) {
			if (OnetrustActiveGroups.match(/,4,/) && OnetrustActiveGroups.match(/,STACK42,/)) {
				console.log("TCF20Enabled -> using Goldbach Ads")
				enableAds.set(2);
			} else {
				console.log("No TCF20Enabled -> using our Ads")
				enableAds.set(1);
			}
			
			if (OnetrustActiveGroups.match(/,1,2,3,4,STACK42,/)) {
				console.log("AllEnabled")
			}
			
			if (OnetrustActiveGroups.match(/,2,/)) {
				console.log("TrackingEnabled")
			}
		}
		
		if (window.dataLayer) {
			window.dataLayer.push({event:'OneTrustGroupsUpdated'})
		}
	}

	if (oneTrustId && window) {
		if (typeof OptanonWrapper === "function") {
    	    console.error("OptanonWrapper already defined. Is this intended? We'll redefine it.");
	    }

		window.OptanonWrapper = function() {
			handleCookieConsent();
		}
    }

</script>

<svelte:head>
	<script async id="otSDKStub" src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" charset="UTF-8" data-domain-script={oneTrustId} AMP="true" />
</svelte:head>
