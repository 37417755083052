<footer>
  <div class="py-[8.75rem] mt-20 px-2.5 bg-A1_footer text-xs text-A1_dgray leading-6">
    <nav>
      <div class="block"> 
        <ul class="flex flex-wrap place-content-center"> 
          <li class="mx-[0.9375rem] mb-6"> <a href="https://www.a1.net/agb" title="AGB" data-index="0" class="">AGB</a> </li> 
          <li class="mx-[0.9375rem] mb-6"> <a href="https://www.a1.net/impressum" title="Impressum" data-index="1" class="">Impressum</a> </li> 
          <li class="mx-[0.9375rem] mb-6"> <a href="https://www.a1.net/datenschutz" title="Datenschutz" data-index="2" class="">Datenschutz</a> </li> 
          <li class="mx-[0.9375rem] mb-6"> <a href="https://www.a1.net/cookie-info" title="Cookie-Einstellungen" data-index="3" class="">Cookie-Einstellungen</a> </li> 
          <li class="mx-[0.9375rem] mb-6 text-A1_white"> <a href="/" title="Weitere Links" data-index="4" class="">Weitere Links</a> </li> 
        </ul> 
      </div>

    </nav>
    <div class="text-center"> <span>© 2022 A1 Telekom Austria Group</span> </div>
    
  </div>
</footer>